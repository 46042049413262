<template>
    <div class="activity" ref="container" @touchstart="swipeStart($event)" @touchend="swipeEnd($event)">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="head_title">Activity Score</div>
            <div class="r">
                <router-link to="/activity/help">
                    <div class="btn">Learn More</div>
                </router-link>
            </div>
        </div>
        <div class="main">
            <div class="l" v-if="loading">
                <loader/>
            </div>
            <div v-else>
                <user v-for="person in users" :key="person.username" :person="person"/>
            </div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

import loader from '../../components/loader.vue';
import user from '../../components/activity/user.vue'

import pull_to_refresh from '../../mixins/pull_to_refresh.js'

export default {
    components: {
        back,
        loader,
        user
    },
    mixins: [pull_to_refresh],
    data() {
        return {
            loading: false,
            users: []
        }
    },
    mounted() {
        this.get_activity_score()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        refresh() {
            this.get_activity_score()
        },
        get_activity_score() {
            this.loading = true

            this.$http.post('/activity/score')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.users = r.users
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style scoped>

    .head {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
        background-color: white;

        position: relative;
    }
    .head_title {
        font-weight: 500;
    }
    .r {
        position: absolute;
        right: 15px;
    }
    .btn {
        font-size: 10px;
        background-color: var(--main);
        color: var(--trans);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        padding: 7px 10px;
    }
    .main {
        padding: 15px;
        height: calc(100vh - 51px);
        box-sizing: border-box;
        overflow: auto;
        background-color: #edeef0;
    }
    .l {
        margin: 30px 0px;
    }
</style>